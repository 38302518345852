import React from 'react'
import OrderTracks from '../Component/OrderTracks/OrderTracks'

const OrderTrack = () => {
  return (
    <>
<OrderTracks/>
    </>
  )
}

export default OrderTrack