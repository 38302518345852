import React from 'react'
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/request.module.css'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../Redux/slices/cartslice';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Nav from '../../Layout/Nav';
const OrderBasket = () => {
    const CartItems = useSelector(state => state.cart.cartItems)
    console.log(CartItems,'CartItems')
    const dispatch = useDispatch()
  const deleteItm = (id) => {
      dispatch(cartActions.deleteItem(id))
  }
  const handleIncrement = (rowId) => {
   
    dispatch(cartActions.plusItem(rowId))
  };
  
  const handleDecrement = (rowId) => {
   
    dispatch(cartActions.reduceItem(rowId))
  };
  return (
    <>
<section className={`${styles.home}`}>
        <div className={`${styles.homeContainer}`}>
        <Nav active="orderbasket" />
        <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow >
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">المنتج</TableCell>
                    <TableCell align="center">السعر</TableCell>
                    <TableCell align="center">الكمية</TableCell>
                    <TableCell align="center">المجموع</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    CartItems.map(cart=>(
                      <>
                        <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={cart.itemCode}
                  >
                    <TableCell align="center">
                      <DeleteIcon className={`${styles.del}`} onClick={()=>deleteItm(cart.itemColor)}/>
                    </TableCell>
                    <TableCell align="center">
                      <div className={`${styles.prod__body}`}>
                     
                        <div>
                          <p> {cart.itemName}</p>
                          <p>المقاس: {cart.itemSize}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{cart.price} جنية</TableCell>
                    <TableCell align="center">
                     
                      <div className={`${styles.number__bodyy}`}>
          <button onClick={() => handleIncrement(cart.itemCode)}>
            <AddIcon />
          </button>
          <h5>{cart.quantity}</h5>
          <button onClick={() => handleDecrement(cart.itemCode)}>
            <RemoveIcon />
          </button>
        </div>
                    </TableCell>
                    <TableCell align="center">{cart.price*cart.quantity} جنية</TableCell>
                  </TableRow>
                      </>
                    ))
                  }
                 
               
               
                </TableBody>
              </Table>
            </TableContainer> 
        </div>
        </section>
    </>
  )
}

export default OrderBasket