import React from 'react'
import Orderrequest from '../Component/OrderRequest/Orderrequest'

const OrderRequest = () => {
  return (
    <>
<Orderrequest/>
    </>
  )
}

export default OrderRequest