import React from 'react'
import AccountPage from '../Component/Account/AccountPage'

const Account = () => {
  return (
    <>
<AccountPage/>
    </>
  )
}

export default Account