import React from 'react'
import { useMemo ,useState} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styles from '../../Styles/request.module.css'
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { cartActions } from '../Redux/slices/cartslice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const TableRequest = () => {

    const [data, setTableData] = useState([
        {
            id:'1',
          name: {
            firstName: 'John',
            lastName: 'Doe',
          },
          address: '261 Erdman Ford',
          city: 'East Daphne',
          state: 'Kentucky',
          quantity:0,
          price:50
        },
        {
            id:'2',
          name: {
            firstName: 'Jane',
            lastName: 'Doe',
          },
          address: '769 Dominic Grove',
          city: 'Columbus',
          state: 'Ohio',
          quantity:0,
          price:100
        },
        {
            id:'3',
          name: {
            firstName: 'Joe',
            lastName: 'Doe',
          },
          address: '566 Brakus Inlet',
          city: 'South Linda',
          state: 'West Virginia',
          quantity:0,
          price:500
        },
        {
            id:'4',
          name: {
            firstName: 'Kevin',
            lastName: 'Vandy',
          },
          address: '722 Emie Stream',
          city: 'Lincoln',
          state: 'Nebraska',
          quantity:0,
          price:800
        },
        {
            id:'5',
          name: {
            firstName: 'Joshua',
            lastName: 'Rolluffs',
          },
          address: '32188 Larkin Turnpike',
          city: 'Charleston',
          state: 'South Carolina',
          quantity:0,
          price:900
        },
      ]);

    const handleIncrement = (rowId) => {
      setTableData((prevData) =>
        prevData.map((row) =>
          row.id == rowId ? { ...row, quantity: (row.quantity || 0) + 1 } : row
        )
      );
      dispatch(cartActions.plusItem(rowId))
    };
    
    const handleDecrement = (rowId) => {
      setTableData((prevData) =>
        prevData.map((row) =>
          row.id == rowId
            ? { ...row, quantity: Math.max((row.quantity || 0) - 1, 0) }
            : row
        )
      );
      dispatch(cartActions.reduceItem(rowId))
    };

const [selectRow,setSelectRow]=useState([])
const dispatch = useDispatch();
const addToCart = (row) => {
  if(row.original.quantity==0){
    toast.error('you must add quantity')
  }else{
    dispatch(
      cartActions.addItem({
        itemCode: row.original.id,
        itemColor: row.original.state,
        itemSize:row.original.city,
        quantity: row.original.quantity,
        price: row.original.price,
        itemName:row.original.address,
        
      })
  )
  toast.success('added succesfully')
  }
console.log(row)

  } 

    const columns = useMemo(
        () => [
          {
            accessorKey: 'name.firstName', //access nested data with dot notation
            header: 'First Name',
            size: 150,
          },
          {
            accessorKey: 'name.lastName',
            header: 'Last Name',
            size: 150,
          },
          {
            accessorKey: 'address', //normal accessorKey
            header: 'Address',
            size: 200,
          },
          {
            accessorKey: 'city',
            header: 'City',
            size: 150,
          },
          {
            accessorKey: 'state',
            header: 'State',
            size: 150,
          },
          {
            accessorKey: "quantity",
            header: "Quantity",
            columnDefType: "display",
            enableColumnOrdering: 1,
            Cell: ({ renderedCellValue, row, cell }) => (
              <>
              <div className={`${styles.number__body}`}>
          <button onClick={() => handleIncrement(row.original.id)}>
            <AddIcon />
          </button>
          <h5>{row.original.quantity || 0}</h5>
          <button onClick={() => handleDecrement(row.original.id)}>
            <RemoveIcon />
          </button>
        </div>
              </>
            ),
          },
          {
            accessorKey: "Action",
            header: "Action",
            columnDefType: "display",
            enableColumnOrdering: 1,
            Cell: ({ renderedCellValue, row, cell }) => (
              <>
             <Button onClick={()=>addToCart(row)}>Add To Cart</Button>
              </>
            ),
          },
        ],
        [],
      );
      const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      });
  return (
    <>
    <section className={`${styles.req}`}>
    <div className='OrderBasket__btn'>
        <Button > 
          <Link to='/OrderBasket' className={`${styles.OrderBasket}`}>
          عرض سلة الاوردرات
          </Link>
        </Button>
    </div>
<MaterialReactTable table={table} />
<ToastContainer/>
</section>
    </>
  )
}

export default TableRequest