import React from 'react'
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/request.module.css'
import TableRequest from './TableRequest'
import Nav from '../../Layout/Nav'
const Orderrequest = () => {
  return (
    <>
  <section className={`${styles.home}`}>
       
        <div className={`${styles.homeContainer}`}>
        <Nav active="orderRequest" />
<TableRequest/>
        </div>
        </section>
    </>
  )
}

export default Orderrequest