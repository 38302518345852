import { useContext, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Nav from './Layout/Nav';
import { useDispatch } from 'react-redux';
import { login } from './Component/Redux/slices/UserSlice';

import Login from './Component/Login/Login'

import Protectpages from './Component/ProtectPage'
import Sidebar from './Layout/Sidebar';
import Home from './Pages/Home';
import Account from './Pages/Account';
import OrderRequest from './Pages/OrderRequest';
import OrderBasket from './Component/OrderBasket/OrderBasket';
import 'react-toastify/dist/ReactToastify.css';
import OrderTrack from './Pages/OrderTrack';
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const usertoken = localStorage.getItem('token');
    if (usertoken) {
      try {
        const parsedToken = JSON.parse(usertoken);
        const userName = localStorage.getItem('userName');
        dispatch(login(parsedToken, userName));
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
    console.log(usertoken)
  }, [dispatch]);
  return (

    <div className="App">
      <BrowserRouter>

        <Routes>
     {/*    <Route element={<Protectpages />}> */}
        <Route path="/" element={<Home />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/orderRequest" element={<OrderRequest />} />
        <Route path="/OrderBasket" element={<OrderBasket />} />
        <Route path="/OrderTracks" element={<OrderTrack />} />
{/* 
          </Route> */}
          <Route path="/login" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
