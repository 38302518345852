import React from 'react'
import styles from '../../Styles/account.module.css'
import Sidebar from '../../Layout/Sidebar'
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import Nav from '../../Layout/Nav';
import TableAcc from './TableAcc';
export const data = [
  {
    firstName: 'مبيعات',
    address: '15/1/2024',
    city: '150000',
    state: 'اجل',
    subRows: [
      {
        firstName: '01001',
        address: '15/1/2024',
        city: '150000',
        state: 'اجل',
      },
      {
        firstName: '01002',
        address: '15/1/2024',
        city: '150000',
        state: 'اجل',
      },
    ],
  },
  {
    firstName: 'استرادات',
    address: '15/1/2024',
    city: '150000',
    state: 'اجل',
    subRows: [
      {
        firstName: '01258',
        address: '15/1/2024',
        city: '150000',
        state: 'اجل',
      },
    ],
  },
];


const AccountPage = () => {
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },

      {
        accessorKey: 'address',
        header: 'التاريخ',
      },
      {
        accessorKey: 'city',
        header: 'السعر',
      },

      {
        accessorKey: 'state',
        enableColumnOrdering: false,
        header: 'State',
      },
    ],
    [],
    //end
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false, //hide expand all double arrow in column header
    enableExpanding: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.subRows, //default
    initialState: { expanded: true }, //expand all rows by default
    paginateExpandedRows: false, //When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
  });
    
  return (
    <>
 <section className={`${styles.home}`}>
        
        <div className={`${styles.homeContainer}`}>
        <Nav active="account" />
        {/* <MaterialReactTable table={table} /> */}
        <TableAcc/>
        </div>
        </section>
    </>
  )
}

export default AccountPage